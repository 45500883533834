import { ButtonProps, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';
import activeBg from '@/assets/betroyale/images/backgrounds/buttonActiveBg.png';

const button = {
  fontFamily: 'RobotoCondensed',
  fontWeight: 'bold',
  fontSize: 'sm',
  textTransform: 'uppercase',
  h: '9',
  '&&': {
    borderRadius: 'md',
  },
};

export const primary = {
  ...button,
  bg: 'beta.500',
  boxShadow:
    '0px 0px 0px 2px #FFA902 inset, 0px 0px 0px 4px #744f0a inset, 0px 0px 0px 5px #ffd076 inset, 0px 3px 10px 0px rgba(0, 0, 0, 0.40)',
  color: 'blackAlpha.700',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',

  _disabled: {
    bg: 'gray.400',
    borderWidth: '2px',
    borderColor: 'gray.300',
    color: 'blackAlpha.400',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow:
      '0px 0px 0px 2px #CBD5E0 inset, 0px 0px 0px 4px #00000087 inset, 0px 0px 0px 5px #CBD5E0 inset',
    border: 'none',
    opacity: '1',

    _hover: { bg: 'gray.400' },
  },

  '&[data-active="true"]': {
    bg: 'alpha.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.40) inset',
    color: 'beta.500',
    textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
    border: 'none',

    '&&': {
      bgImg: `url(${activeBg})`,
      bgRepeat: 'no-repeat',
      bgPosition: '0% 12%,18% 11%,90% 91%',
      bgSize: '100%',
    },
  },

  _hover: {
    bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #FFB11A',
  },
  _focus: {
    bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #FFB11A',
  },
};

const secondary = {
  ...button,
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  boxShadow:
    '0px 0px 0px 2px #FFA902 inset, 0px 0px 0px 4px #0C2549 inset, 0px 0px 0px 5px #4d98c5 inset, 0px 3px 10px 0px rgba(0, 0, 0, 0.40)',
  color: 'white',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.40)',
  fontWeight: 'black',

  _hover: {
    bgGradient: 'linear(to-b, alpha.800, alpha.900)',
  },
  '&:active': {
    bg: 'beta.800',
    boxShadow: 'inset 0px 3px 0px rgba(0, 0, 0, 0.2)',
  },

  '&:disabled': {
    bg: 'blackAlpha.200',
    boxShadow: 'inset 0px 3px 4px #000000',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      border: '2px',
      borderColor: 'white',
      color: 'white',
      fontWeight: 'bold',
      filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.20))',

      '&:active, &:hover': {
        bg: 'whiteAlpha.300',
        borderColor: 'white',
      },
    }),
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      textTransform: 'uppercase',
      _hover: {
        color: 'beta.500',
      },
      _active: {
        bg: 'beta.500',
        borderRadius: 'base',
        color: 'alpha.900',
        fontSize: '2xs',
        fontWeight: 'bold',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
      },
    },
    filter: {
      border: '2px',
      borderColor: 'whiteAlpha.300',
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '6',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',
      _hover: {
        color: 'alpha.300',
      },
      _active: {
        border: 'none',
        color: 'alpha.900',
        bg: '#FFB11A',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset',
      },
    },
    tab: {
      ...secondary,
      minW: 'unset',
      px: '2',
      py: '3',
      h: '5',
      fontSize: '2xs',
      _focus: {
        bg: 'blackAlpha.600',
        boxShadow: '0px 1px 0px 0px #000 inset',
        color: 'beta.500',
      },
      _hover: {
        bgGradient: 'linear(180deg, alpha.800, alpha.900)',
        boxShadow:
          '0px 0px 0px 2px #FFA902 inset, 0px 0px 0px 4px #0C2549 inset, 0px 0px 0px 5px #4d98c5 inset',
      },

      '&[data-active="true"]': {
        bg: 'blackAlpha.600',
        boxShadow: '0px 1px 0px 0px #000 inset',
        color: 'beta.500',
      },
    },
    quaddiesTab: {
      boxShadow: 'none',
      fontSize: 'xs',
      textShadow: 'none',
      fontWeight: 'bold',
      borderRadius: 'none',
      fontFamily: 'body',
      h: '10',
      textTransform: 'uppercase',
      color: 'white',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '1',
      _hover: {
        color: 'alpha.400',
      },
      ':active, &[data-active="true"]': {
        color: 'alpha.600',
        fontWeight: 'normal',
        borderBottom: '3px solid',
        borderBottomColor: 'alpha.600',
      },
    },
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',
    }),
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
